<template>
  <v-card>
    <v-card-title class="justify-center">
      {{ $t('expressions|successfuly_activated_private_account') }}
    </v-card-title>
    <v-card-text class="text-center">
      <div>
        <v-btn
          color="primary"
          to="/members"
          class="mt-8"
        >
          {{ $t('actions|go_to_dashboard') }}
        </v-btn>
        <div class="mt-2">
          redirect in {{ countDown }}
        </div>
      </div>
      <img
        src="@/assets/account-activation.png"
        :height="($vuetify.breakpoint.height - 232).toString() + 'px'"
        width="auto"
      >
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  data () {
    return {
      countDown: 10
    }
  },
  created () {
    this.countDownTimer()
  },
  methods: {
    countDownTimer () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        if (this.$route.name === 'AccountActivationSuccessPage') this.$router.push('/dashboard')
      }
    }
  }
}
</script>
